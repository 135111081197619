import { CloseOutlined, FilePdfTwoTone, KeyOutlined, MailOutlined, PhoneOutlined, PlusCircleOutlined, SaveOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Image, Modal, Row, Table } from 'antd';
import { FormAction, FormContainer, FormInput, FormInputMask, FormInputPassword, FormSelect, FormSwitch, FormTreeSelect } from "components/high-level/form";
import FormDatepicker from 'components/high-level/form/form-date-picker';
import FormMultiSelect from "components/high-level/form/form-multi-select";
import Validator from "components/high-level/validator";
import i18next from "i18next";
import EventBus from "infrastructure/event-bus/event-bus";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PDFReader } from 'reactjs-pdf-view';
import { Actions as ApiCallActions } from "redux/apiCall/reducers";
import { DocumentTypeEnum, OrganizationTypeEnum, StatusEnum, UserTypeEnum } from 'utils/enums';
import { EntityTypeEnum } from "utils/enums/entity-type-enum";
import general from "utils/general";
import useRefState from "utils/use-ref-state";
const { innerHeight: windowHeight } = window
const { Column } = Table;

const UserDocumentsModal = ({ nodeKey, id }) => {
  const dispatch = useDispatch();
  const [pdfKey, setPdfKey] = useState(general.generateRandomString());
  const [isOpen, setIsOpen] = useState(true);
  const closeModal = () => {
    setIsOpen(false);
  }
  const [isPdfOpen, setIsPdfOpen] = useState(false);
  const closePdfModal = () => {
    setIsPdfOpen(false);
  }
  const [pdfFileUrl, setPdfFileUrl] = useState(null);
  const openPdfModal = (file) => {
    setPdfFileUrl(file);
    setIsPdfOpen(true);
    setPdfKey(general.generateRandomString());
  }
  const [, reRender] = useState(null);
  const [validator,] = useState(new Validator());
  const validatorScopeKey = validator.generateNewScopeKey();
  const [loading, setLoading] = useState(false);
  const [formProps, formPropsRef, setFormProps] = useRefState({});
  const updateFormProps = (values) => {
    setFormProps(curr => ({ ...curr, ...values, }))
  };

  useEffect(() => {
    if (!general.isNullOrEmpty(id)) {
      setLoading(true);
      dispatch(ApiCallActions.Detail({
        controller: "user",
        itemId: id,
        showLoading: true,
        showAlertOnError: true,
        onSuccess: async ({ data }) => {
          var newData = {
            ...data,
            roleId: data?.role?.id,
            roleName: data?.role?.name,
          }
          setLoading(false);
          setFormProps(newData);
        }
      }))
    }
  }, []);

  const imageRenderer = useCallback((src) => {
    var file = "";
    if (!general.isNullOrEmpty(src)) {
      file = JSON.parse(src);
      if (file?.length > 0)
        file = file[0]?.downloadUrl;
      else if (file?.downloadUrl?.length > 0)
        file = file?.downloadUrl;
      else
        file = undefined;
    }
    let mime = "image";
    if (general.isNullOrEmpty(file))
      file = require("assets/logo.png");
    else {
      var fileExt = file.split('.').pop();
      if (fileExt == "pdf")
        mime = "pdf";
      else
        mime = "image";
    }
    if (mime == "image")
      return <Image className='rounded' width={100} src={file} />
    else return <Button onClick={() => openPdfModal(file)}><FilePdfTwoTone /> PDF</Button>;
  }, [])

  const documentStrings = {
    [DocumentTypeEnum.UserIdCard]: i18next.t("enum.document_type.user_id_card"),
    [DocumentTypeEnum.UserDocuments]: i18next.t("enum.document_type.user_documents"),
    [DocumentTypeEnum.ProfilePhoto]: i18next.t("enum.document_type.user_profile"),
  }

  return (
    <>
      <Modal
        title={i18next.t("user.form_title.documents")}
        visible={isOpen}
        width={1000}
        onCancel={closeModal}
        okButtonProps={{
          disabled: true
        }}
        cancelText={i18next.t("action.close")}
      >
        <Table dataSource={formProps.userDocuments} pagination={false}>
          <Column title={i18next.t("general.document_type")} dataIndex="documentType" key="documentType"
            render={(documentType) => documentStrings[documentType] || ''} />
          <Column
            title={i18next.t("general.document")}
            dataIndex="documentUrl"
            key="documentUrl"
            render={(documentUrl) => (
              imageRenderer(documentUrl)
            )}
          />
        </Table>
      </Modal >
      <Modal
        title={i18next.t("user.form_title.documents")}
        visible={isPdfOpen}
        width={1000}
        height={'100%'}
        style={{ top: 20 }}
        onCancel={closePdfModal}
        okButtonProps={{
          disabled: true
        }}
        cancelText={i18next.t("action.close")}
      >
        {
          pdfFileUrl &&
          <object key={pdfKey} data={"https://docs.google.com/viewer?url=" + pdfFileUrl + "&embedded=true"} type="application/pdf" width={'100%'} height={windowHeight - 220}>
            <iframe src={"https://docs.google.com/viewer?url=" + pdfFileUrl + "&embedded=true%22%3E"}></iframe>
          </object>
        }
      </Modal >
    </>
  )
}

export default UserDocumentsModal;
