import "components/high-level/rodal/rodal.css";
import InsuranceCompaniesFormModal from "modals/insurance-companies/insurance-companies-form";
import LanguageFormModal from "modals/language/language-form";
import LocationFormModal from "modals/location/location-form";
import RoleFormModal from "modals/role/role-form";
import ServiceTypeFormModal from "modals/service-type/service-type-form";
import MissingTranslateFormModal from "modals/translate/missing-translate-form";
import TranslateFormModal from "modals/translate/translate-form";
import ChangePasswordModal from "modals/user/change-password";
import ChangePasswordProfileModal from "modals/user/change-password-profile";
import UserAddressModal from "modals/user/user-address";
import UserDocumentsModal from "modals/user/user-documents";
import UserFormModal from "modals/user/user-form";
import UserInterestServiceTypesModal from "modals/user/user-interest-service-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Actions as ModalActions } from "redux/modal/reducers";
import ModalTypes from "utils/modal-types";

const ModalRenderContainer = ({ }) => {
  const dispatch = useDispatch();
  const modals = useSelector(state => state.modal.modals);
  const history = useHistory()
  useEffect(() => {
    return history.listen((location) => {
      dispatch(ModalActions.setModalState({ modals: [] }))
    })
  }, [history])

  return (
    <div>
      {modals?.map(item => <div key={item?.nodeKey}>
        {item.type === ModalTypes.USER_FORM &&
          <UserFormModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} />
        }
        {item.type === ModalTypes.USER_ADDRESS &&
          <UserAddressModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} />
        }
        {item.type === ModalTypes.USER_DOCUMENTS &&
          <UserDocumentsModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} />
        }
        {item.type === ModalTypes.ROLE_FORM &&
          <RoleFormModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} />
        }
        {item.type === ModalTypes.LANGUAGE_FORM &&
          <LanguageFormModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} />
        }
        {item.type === ModalTypes.TRANSLATE_FORM &&
          <TranslateFormModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} />
        }
        {item.type === ModalTypes.MISSING_TRANSLATE_FORM &&
          <MissingTranslateFormModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} />
        }
        {item.type === ModalTypes.LOCATION_FORM &&
          <LocationFormModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} />
        }
        {item.type === ModalTypes.SERVICE_TYPE_FORM &&
          <ServiceTypeFormModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} />
        }
        {item.type === ModalTypes.CHANGE_PASSWORD_PROFILE_MODAL &&
          <ChangePasswordProfileModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} />
        }
        {item.type === ModalTypes.CHANGE_PASSWORD_MODAL &&
          <ChangePasswordModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} />
        }
        {item.type === ModalTypes.INSURANCE_COMPANIES_FORM &&
          <InsuranceCompaniesFormModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} />
        }
        {item.type === ModalTypes.USER_INTEREST_SERVICE_TYPES_MODAL &&
          <UserInterestServiceTypesModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} />
        }
      </div>)}
    </div>)
}

export default ModalRenderContainer;
