import { FileWordOutlined, KeyOutlined } from '@ant-design/icons';
import { Col, Modal, Row } from 'antd';
import CustomFlag from "components/high-level/customFlag";
import { FormAction, FormContainer, FormInput, FormInputMask, FormInputPassword, FormSelect, FormSwitch, FormTreeSelect } from "components/high-level/form";
import FormMultiSelect from "components/high-level/form/form-multi-select";
import Validator from "components/high-level/validator";
import i18next from "i18next";
import EventBus from "infrastructure/event-bus/event-bus";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Actions as ApiCallActions } from "redux/apiCall/reducers";
import { OrganizationTypeEnum, StatusEnum, UserTypeEnum } from 'utils/enums';
import { EntityTypeEnum } from "utils/enums/entity-type-enum";
import general from "utils/general";
import useRefState from "utils/use-ref-state";

const TranslateFormModal = ({ nodeKey, id }) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(true);
    const closeModal = () => {
        setIsOpen(false);
    }

    const [, reRender] = useState(null);
    const [validator,] = useState(new Validator());
    const validatorScopeKey = validator.generateNewScopeKey();

    const [loading, setLoading] = useState(false);
    const [formProps, formPropsRef, setFormProps] = useRefState({});
    const updateFormProps = (values) => {
        setFormProps(curr => ({ ...curr, ...values, }))
    };
    const [loadAndSelectDeviceGroup, setLoadAndSelectDeviceGroup] = useState([]);
    const [loadAndSelectOrganizationGroup, setLoadAndSelectOrganizationGroup] = useState([]);

    useEffect(() => {
        dispatch(ApiCallActions.List({
            controller: "language",
            action: "list",
            showAlertOnError: true,
            data: {
                limit: 100,
                page: 1,
                sortby: { key: "id", order: "desc" },
                getButtonsAuthority: true,
            },
            onSuccess: ({ data }) => {
                updateFormProps({ translateList: data });
            },
            onError: () => { }
        }))
        if (!general.isNullOrEmpty(id)) {
            setLoading(true);
            dispatch(ApiCallActions.Detail({
                controller: "dataTranslate",
                itemId: id,
                showLoading: true,
                showAlertOnError: true,
                onSuccess: async ({ data }) => {
                    var newData = {
                        ...data
                    }
                    setLoading(false);
                    setFormProps(newData);
                }
            }))
        }
    }, []);

    const save = useCallback(() => {
        const isValid = validator.allValid();
        reRender(1);
        if (isValid) {
            setLoading(true);
            if (general.isNullOrEmpty(id)) { // create
                dispatch(ApiCallActions.Save({
                    controller: "dataTranslate",
                    data: { ...formProps },
                    showLoading: true,
                    onSuccess: ({ data }) => {
                        setIsOpen(false);
                        general.messageSuccess("general.save_success");

                        data.map((item) => {
                            EventBus.sc.onEntityCreated({ entityType: EntityTypeEnum.Translate, entityId: item.id });
                        })
                    },
                    onError: (message) => general.notificationError(message.errorMessageTechnical),
                    callback: () => setLoading(false)
                }))

            } else { // update
                dispatch(ApiCallActions.Update({
                    controller: "dataTranslate",
                    itemId: id,
                    data: { ...formProps },
                    onSuccess: ({ data }) => {
                        data.map((item) => {
                            EventBus.sc.onEntityCreated({ entityType: EntityTypeEnum.Translate, entityId: item.id });
                        })
                        EventBus.sc.onEntityUpdated({ entityType: EntityTypeEnum.Translate, entityId: id });
                        setIsOpen(false);
                        general.messageSuccess("general.update_success");
                    },
                    onError: (message) => general.notificationError(i18next.t(message.errorMessageTechnical)),
                    callback: () => setLoading(false)
                }))
            }
        }
    }, [formProps])

    const onChangeKeyword = useCallback((val) => updateFormProps({ keyword: val }), []);
    const onChangeValue = useCallback((val, index) => {
        let translateList = formPropsRef.current.translateList;
        translateList[index].value = val;
        updateFormProps({ translateList: translateList });
    }, []);
    const onChangeStatus = useCallback((val) => updateFormProps({ status: val ? StatusEnum.Active : StatusEnum.Passive }), []);
    const onChangeLanguage = useCallback((val) => updateFormProps({ languageId: val.Id }), []);

    return (
        <Modal
            title={general.isNullOrEmpty(id) ? i18next.t("translate.form_title.add") : i18next.t("translate.form_title.edit")}
            visible={isOpen}
            width={700}
            onCancel={closeModal}
            cancelText={i18next.t("action.close")}
            okText={i18next.t("action.save")}
            onOk={save}
        >
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <FormInput
                        label={i18next.t("translate.form.keyword")}
                        placeholder={i18next.t("translate.form.keyword")}
                        onChange={onChangeKeyword}
                        value={formProps?.keyword}
                        prefix={<KeyOutlined />}
                        errorMessage={validator.register("keyword", formProps?.keyword, [{ rule: "required" }, { rule: "maxStringLength", value: 100 }], validatorScopeKey)}
                    />
                </Col>
                <h6 style={{ borderBottom: "1px solid #d9d9d9", paddingBottom: 5 }}>{i18next.t("general.translates")}</h6>
                {formProps?.translateList?.map((item, ind) => {
                    return (
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Row className="pt-1">
                                <Col xs={6} sm={6} md={6} lg={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <CustomFlag flagName={general.isNullOrEmpty(id) ? item.shortName : item?.language?.shortName} />
                                    <span className="ml-3">{general.isNullOrEmpty(id) ? item.name : item?.language?.name}</span>
                                </Col>
                                <Col xs={18} sm={18} md={18} lg={18} className="pt-4">
                                    <FormInput
                                        placeholder={i18next.t("translate.form.value")}
                                        onChange={(value) => onChangeValue(value, ind)}
                                        value={item.value}
                                        prefix={<FileWordOutlined />}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    );
                })
                }
            </Row>
        </Modal>
    )
}

export default TranslateFormModal;