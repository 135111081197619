export enum EntityTypeEnum {
    User = 1,
    Role = 2,
    Language = 3,
    Translate = 4,
    Location = 5,
    ServiceType = 6,
    MissingTranslate = 7,
    Work = 8,
    InsuranceCompanies = 9
}