import React, { useCallback, useEffect, useState } from "react"
import { Actions as ApiCallActions } from "redux/apiCall/reducers"
import { useDispatch } from "react-redux";
import Validator from "components/high-level/validator";
import { FormInputPassword, FormInput, FormAction, FormInputMask, FormContainer, FormSelect, FormSwitch, FormTreeSelect } from "components/high-level/form";
import { CloseOutlined, MailOutlined, SaveOutlined, UserOutlined, KeyOutlined, PlusCircleOutlined, PhoneOutlined } from '@ant-design/icons';
import general from "utils/general";
import useRefState from "utils/use-ref-state";
import { Modal, Row, Col } from 'antd';
import i18next from "i18next";
import { StatusEnum, UserTypeEnum, OrganizationTypeEnum } from 'utils/enums';
import EventBus from "infrastructure/event-bus/event-bus";
import { EntityTypeEnum } from "utils/enums/entity-type-enum";
import FormMultiSelect from "components/high-level/form/form-multi-select";

const LocationFormModal = ({ nodeKey, id }) => {
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(true);
    const closeModal = () => {
        setIsOpen(false);
    }

    const [, reRender] = useState(null);
    const [validator,] = useState(new Validator());
    const validatorScopeKey = validator.generateNewScopeKey();

    const [loading, setLoading] = useState(false);
    const [formProps, formPropsRef, setFormProps] = useRefState({});
    const updateFormProps = (values) => {
        setFormProps(curr => ({ ...curr, ...values, }))
    };
    const [loadAndSelectDeviceGroup, setLoadAndSelectDeviceGroup] = useState([]);
    const [loadAndSelectOrganizationGroup, setLoadAndSelectOrganizationGroup] = useState([]);
    const [parentOrganization, setParentOrganization] = useState(null);

    useEffect(() => {
        updateFormProps({
            userType: global?.userInfo?.userType,
            userTypeName: global?.userInfo?.userTypeName
        })
    }, [])

    const generatePassword = useCallback(() => {
        updateFormProps({
            password: general.generateRandomString(10)
        })
    })

    useEffect(() => {
        if (!general.isNullOrEmpty(id)) {
            setLoading(true);
            dispatch(ApiCallActions.Detail({
                controller: "location",
                itemId: id,
                showLoading: true,
                showAlertOnError: true,
                onSuccess: async ({ data }) => {
                    var newData = {
                        ...data
                    }
                    setLoading(false);
                    setFormProps(newData);
                }
            }))
        }
    }, []);

    const save = useCallback(() => {
        const isValid = validator.allValid();
        reRender(1);
        if (isValid) {
            setLoading(true);
            if (general.isNullOrEmpty(id)) { // create
                dispatch(ApiCallActions.Save({
                    controller: "location",
                    data: { ...formProps },
                    showLoading: true,
                    onSuccess: ({ data }) => {
                        EventBus.sc.onEntityCreated({ entityType: EntityTypeEnum.Location, entityId: data.id });
                        setIsOpen(false);
                        general.messageSuccess("general.save_success");
                    },
                    onError: (message) => general.notificationError(message.errorMessageTechnical),
                    callback: () => setLoading(false)
                }))

            } else { // update
                dispatch(ApiCallActions.Update({
                    controller: "location",
                    itemId: id,
                    data: { ...formProps },
                    onSuccess: () => {
                        EventBus.sc.onEntityUpdated({ entityType: EntityTypeEnum.Location, entityId: id });
                        setIsOpen(false);
                        general.messageSuccess("general.update_success");
                    },
                    onError: (message) => general.notificationError(i18next.t(message.errorMessageTechnical)),
                    callback: () => setLoading(false)
                }))
            }
        }
    }, [formProps])

    const onChangeName = useCallback((val) => updateFormProps({ name: val, fullName: formPropsRef.current.parentLocationName ? (formPropsRef.current.parentLocationName + " > ") + val : val }), []);
    const onChangeFullName = useCallback((val) => updateFormProps({ fullName: val }), []);
    const onChangeParentLocation = useCallback(
        parentLocation =>
            updateFormProps({ parentLocation: parentLocation?.value, parentLocationName: parentLocation?.name, fullName: parentLocation?.name }),
        [],
    )
    const onChangeStatus = useCallback((val) => updateFormProps({ status: val ? StatusEnum.Active : StatusEnum.Passive }), []);

    return (
        <Modal
            title={general.isNullOrEmpty(id) ? i18next.t("location.form_title.add") : i18next.t("location.form_title.edit")}
            visible={isOpen}
            width={700}
            onCancel={closeModal}
            cancelText={i18next.t("action.close")}
            okText={i18next.t("action.save")}
            onOk={save}
        >
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <FormSelect
                        controller="location"
                        action="dropdown"
                        disabled={id != null}
                        label={i18next.t("form.parent_location")}
                        selectedId={Number(formProps?.parentLocation)}
                        selectedName={i18next.t(formProps?.parentLocationName)}
                        onChange={onChangeParentLocation}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} >
                    <FormInput
                        label={i18next.t("location.form.name")}
                        placeholder={i18next.t("location.form.name")}
                        onChange={onChangeName}
                        value={formProps?.name}
                        errorMessage={validator.register("name", formProps?.name, [{ rule: "required" }, { rule: "maxStringLength", value: 50 }], validatorScopeKey)}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} >
                    <FormInput
                        label={i18next.t("location.form.fullName")}
                        placeholder={i18next.t("location.form.fullName")}
                        onChange={onChangeFullName}
                        value={formProps?.fullName}
                        disabled={true}
                        errorMessage={validator.register("fullName", formProps?.fullName, [{ rule: "required" }, { rule: "maxStringLength", value: 250 }], validatorScopeKey)}
                    />
                </Col>

                {!general.isNullOrEmpty(id) &&
                    <Col xs={24} sm={24} md={24} lg={24} >
                        <FormSwitch
                            label={i18next.t("form.status")}
                            checkedChildren={i18next.t("form.status.active")}
                            unCheckedChildren={i18next.t("form.status.passive")}
                            checked={formProps?.status == StatusEnum.Active}
                            onChange={onChangeStatus}
                        />
                    </Col>
                }
            </Row>
        </Modal>
    )
}

export default LocationFormModal;