import { DownloadOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import general from 'utils/general';
import useRefState from 'utils/use-ref-state';
import FormAction from './form-action';

const FormInput = ({
    label,
    placeholder = "form.select.placeholder",
    value,
    errorMessage,
    onChange,
    onClick,
    prefix,
    suffix,
    antdFormItemProps,
    antdInputProps,
    rightAction,
    multiLine = false,
    rows = 10,
    cols = 10,
    addRequiredSign = false,
    disabled,
    onKeyPress,
    pattern,
    type = "text",
    id,
    dataType,
    usePriceFormat = false,
    onBlur,
    onFocus,
}) => {
    const [formattedValue, formattedValueRef, setFormattedValue] = useRefState(value);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!isLoaded && value || (formattedValueRef.current != value)) {
            if (usePriceFormat) {
                setFormattedValue(general.formatPrice(value))
            }
            else {
                setFormattedValue(value)
            }
            setIsLoaded(true)
        }
    }, [value, isLoaded])

    const onChangeEvent = (e) => {
        let val = e.target.value;
        if (usePriceFormat) {
            setFormattedValue(general.formatPrice(val));
        } else {
            setFormattedValue(val);
        } 
        if (onChange instanceof Function)
            onChange(val);
    }
    const onClickEvent = (e) => {
        if (onClick instanceof Function)
            onClick(e.target.value);
    }

    const onFocusEvent = () => {
        if (onFocus instanceof Function)
            onFocus();

    }
    const onBlurEvent = () => {
        if (onBlur instanceof Function)
            onBlur();
    }

    return (
        <>
            <h6 style={{ fontWeight: 500 }}>{general.isNullOrEmpty(label) ? null : label + (addRequiredSign ? " *" : "")}</h6>
            <Form.Item
                validateStatus={general.isNullOrEmpty(errorMessage) ? "" : "error"}
                help={errorMessage}
                style={{ display: "flex", }}

                {...antdFormItemProps}
            >
                {!multiLine &&
                    <Input
                        datatype={dataType}
                        id={id}
                        type={type}
                        pattern={pattern}
                        prefix={prefix}
                        suffix={suffix}
                        allowClear
                        onKeyPress={onKeyPress}
                        value={formattedValue}
                        disabled={disabled}
                        size="large"
                        onChange={onChangeEvent}
                        onClick={onClickEvent}
                        onFocus={onFocusEvent}
                        onBlur={onBlurEvent}
                        placeholder={i18next.t(placeholder)}
                        {...antdInputProps}
                    />
                }

                {multiLine &&
                    <TextArea
                        prefix={prefix}
                        suffix={suffix}
                        allowClear
                        onKeyPress={onKeyPress}
                        value={formattedValue}
                        rows={rows}
                        cols={cols}
                        size="large"
                        disabled={disabled}
                        onChange={onChangeEvent}
                        onClick={onClickEvent}
                        onFocus={onFocusEvent}
                        onBlur={onBlurEvent}
                        placeholder={i18next.t(placeholder)}
                        {...antdInputProps}
                    />
                }
                {rightAction}
            </Form.Item>
        </>
    )
}

const areEqual = (prev, next) => {
    return prev.label === next.label
        && prev.placeholder === next.placeholder
        && prev.errorMessage === next.errorMessage
        && prev.disabled === next.disabled
        && prev.value == next.value;
}
export default React.memo(FormInput, areEqual);