const ModalTypes = {
  EXAMPLE_FORM: "EXAMPLE_FORM",
  USER_FORM: "USER_FORM",
  USER_DOCUMENTS: "USER_DOCUMENTS",
  ROLE_FORM: "ROLE_FORM",
  LANGUAGE_FORM: "LANGUAGE_FORM",
  TRANSLATE_FORM: "TRANSLATE_FORM",
  MISSING_TRANSLATE_FORM: "MISSING_TRANSLATE_FORM",
  LOCATION_FORM: "LOCATION_FORM",
  SERVICE_TYPE_FORM: "SERVICE_TYPE_FORM",
  USER_ADDRESS: "USER_ADDRESS",
  CHANGE_PASSWORD_PROFILE_MODAL: "CHANGE_PASSWORD_PROFILE_MODAL",
  CHANGE_PASSWORD_MODAL: "CHANGE_PASSWORD_MODAL",
  INSURANCE_COMPANIES_FORM: "INSURANCE_COMPANIES_FORM",
  USER_INTEREST_SERVICE_TYPES_MODAL: "USER_INTEREST_SERVICE_TYPES_MODAL",
}
export default ModalTypes;
