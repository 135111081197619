import React, { useCallback, useEffect, useState } from "react"
import { Actions as ApiCallActions } from "redux/apiCall/reducers"
import { useDispatch } from "react-redux";
import Validator from "components/high-level/validator";
import { FormInputPassword, FormInput, FormAction, FormInputMask, FormContainer, FormSelect, FormSwitch, FormTreeSelect } from "components/high-level/form";
import { CloseOutlined, MailOutlined, SaveOutlined, UserOutlined, KeyOutlined, PlusCircleOutlined, PhoneOutlined, HomeOutlined } from '@ant-design/icons';
import general from "utils/general";
import i18next from "i18next";
import { Modal, Row, Col, Empty, Card, Badge } from 'antd';
import { StatusEnum, UserTypeEnum, OrganizationTypeEnum } from 'utils/enums';
import EventBus from "infrastructure/event-bus/event-bus";
import { EntityTypeEnum } from "utils/enums/entity-type-enum";
import FormMultiSelect from "components/high-level/form/form-multi-select";

const UserAddressModal = ({ nodeKey, id }) => {
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(true);
    const closeModal = () => {
        setIsOpen(false);
    }

    const [, reRender] = useState(null);
    const [validator,] = useState(new Validator());
    const validatorScopeKey = validator.generateNewScopeKey();

    const [loading, setLoading] = useState(false);
    const [formProps, setFormProps] = useState({});
    const updateFormProps = (values) => {
        setFormProps(curr => ({ ...curr, ...values, }))
    };


    useEffect(() => {
        if (!general.isNullOrEmpty(id)) {
            setLoading(true);
            dispatch(ApiCallActions.Detail({
                controller: "user",
                itemId: id,
                showLoading: true,
                showAlertOnError: true,
                onSuccess: async ({ data }) => {
                    var newData = {
                        ...data,
                    }
                    setLoading(false);
                    setFormProps(newData);
                }
            }))
        }
    }, []);

    return (
        <Modal
            title={i18next.t("user.form_title.user_address")}
            visible={isOpen}
            width={1000}
            onCancel={closeModal}
            okButtonProps={{ disabled: true }}
            cancelText={i18next.t("action.close")}
            okText={i18next.t("action.save")}
        >
            {
                (formProps.address && formProps.address.length > 0) ?
                    <>
                        {
                            formProps?.address.map((item, index) => {
                                return <Row className="mt-3" key={"address-" + index}>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <Card>
                                            <Row>
                                                <Col xs={24} sm={24} md={8} lg={8}>
                                                    <h5>{i18next.t("general.city") + " > " + i18next.t("general.county")}</h5>
                                                    <h6>{item.location?.fullName}</h6>
                                                </Col>
                                                <Col xs={24} sm={24} md={6} lg={6}>
                                                    <h5>{i18next.t("general.post_code")}</h5>
                                                    <h6>{item.postCode}</h6>
                                                </Col>
                                                <Col xs={24} sm={24} md={10} lg={10}>
                                                    <h5>{i18next.t("general.full_address")}</h5>
                                                    <h6>{item.fullAddress}</h6>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>

                            })
                        }
                    </>
                    :
                    <Empty description={i18next.t("general.no_data")} />
            }
        </Modal >
    )
}

export default UserAddressModal;