import { RoleActionEnum } from 'utils/enums'
import general from 'utils/general'

export default async function getMenuData() {


  //Setting Menus --------------------------------------------------------
  var settingMenuSubs = []

  var settingMenu = {
    title: 'menu.settings',
    key: 'settings',
    icon: 'fe fe-settings',
    children: settingMenuSubs,
  }

  if (general.hasAccess(RoleActionEnum.RoleList))
    settingMenuSubs.push({
      title: 'menu.role_list',
      key: 'role-list',
      url: '/role/list',
    });

  if (general.hasAccess(RoleActionEnum.UserList))
    settingMenuSubs.push({
      title: 'menu.user_list',
      key: 'user-list',
      url: '/user/list',
    });

  if (general.hasAccess(RoleActionEnum.LanguageList))
    settingMenuSubs.push({
      title: 'menu.language_list',
      key: 'language-list',
      url: '/language/list',
    });

  if (general.hasAccess(RoleActionEnum.TranslateList))
    settingMenuSubs.push({
      title: 'menu.translate_list',
      key: 'translate-list',
      url: '/translate/list',
    });

  if (general.hasAccess(RoleActionEnum.MissingTranslateList))
    settingMenuSubs.push({
      title: 'menu.missing_translate_list',
      key: 'missing-translate-list',
      url: '/missing-translate/list',
    });

  // if (general.hasAccess(RoleActionEnum.LocationList))
  //   settingMenuSubs.push({
  //     title: 'menu.location_list',
  //     key: 'location-list',
  //     url: '/location/list',
  //   });

  if (general.hasAccess(RoleActionEnum.ServiceTypeList))
    settingMenuSubs.push({
      title: 'menu.service_type_list',
      key: 'service-type-list',
      url: '/service-type/list',
    });

  if (general.hasAccess(RoleActionEnum.InsuranceCompaniesList))
    settingMenuSubs.push({
      title: 'menu.insurance_companies_list',
      key: 'insurance-companies-list',
      url: '/insurance-companies/list',
    });

  if (general.hasAccess(RoleActionEnum.UserInviteList))
    settingMenuSubs.push({
      title: 'menu.user_invite_list',
      key: 'user-invite-list',
      url: '/user-invite/list',
    });

  var menu = []
  menu.push({
    title: 'menu.dashboard',
    key: 'dashboard/alpha',
    url: '/dashboard/alpha',
    icon: 'fe fe-home',
  });
  if (general.hasAccess(RoleActionEnum.WorkList))
    menu.push({
      title: 'menu.work',
      key: 'work-list',
      url: '/work/list',
      icon: 'fe fe-list',
    });
  if (settingMenuSubs.length > 0) menu.push(settingMenu)


  return menu
}
