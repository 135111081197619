import React, { useCallback, useEffect, useState } from 'react'
import { Actions as ApiCallActions } from 'redux/apiCall/reducers'
import { useDispatch } from 'react-redux'
import Validator from 'components/high-level/validator'
import { FormContainer, FormInput, FormAction, FormSelect, FormTreeSelect } from 'components/high-level/form'
import { CloseOutlined, SaveOutlined } from '@ant-design/icons'
import general from 'utils/general'
import i18next from 'i18next'
import useRefState from 'utils/use-ref-state'
import { Modal, Row, Col } from 'antd';
import 'antd/dist/antd.css'
import { EntityTypeEnum } from 'utils/enums/entity-type-enum'
import EventBus from 'infrastructure/event-bus/event-bus'
import { UserTypeEnum } from 'utils/enums'

const RoleFormModal = ({ nodeKey, id }) => {
  const dispatch = useDispatch()
  const [, reRender] = useState(null)
  const [validator] = useState(new Validator())
  const validatorScopeKey = validator.generateNewScopeKey()

  const [loading, setLoading] = useState(false)
  const [formProps, formPropsRef, setFormProps] = useRefState({ actions: [], userType: UserTypeEnum.Admin, userTypeName: "Admin" })

  const updateFormProps = values => setFormProps(curr => ({ ...curr, ...values }))

  const [isOpen, setIsOpen] = useState(true)
  const closeModal = () => {
    setIsOpen(false)
  }
  const onChangeRoleAction = useCallback(val => updateFormProps({ actions: val }), [])

  const onChangeName = useCallback(val => updateFormProps({ name: val }), [])

  useEffect(() => {
    if (!general.isNullOrEmpty(id)) {
      setLoading(true)
      dispatch(
        ApiCallActions.Detail({
          controller: 'role',
          itemId: id,
          showLoading: true,
          showAlertOnError: true,
          onSuccess: ({ data }) => {
            setLoading(false)
            setFormProps(data)
          },
        }),
      )
    }
  }, [])

  const translate = data => {
    data.title = i18next.t(data.title)
    if (data.children) {
      data.children.map(child => {
        translate(child)
      })
    }
  }

  const save = useCallback(() => {
    const isValid = validator.allValid()
    reRender(1)
    if (isValid) {
      if (formProps?.actions === null || formProps?.actions?.length == 0) {
        general.notificationWarning(i18next.t('role.validation.action'))
        return
      }

      setLoading(true)
      if (general.isNullOrEmpty(id)) {
        // create
        dispatch(
          ApiCallActions.Save({
            controller: 'role',
            data: { ...formProps },
            onError: message => general.notificationError(message.errorMessageTechnical),
            showLoading: true,
            onSuccess: ({ data }) => {
              EventBus.sc.onEntityCreated({ entityType: EntityTypeEnum.Role, entityId: data.id })
              setIsOpen(false)
              general.messageSuccess('general.save_success')
            },
            callback: () => setLoading(false),
          }),
        )
      } else {
        // update
        dispatch(
          ApiCallActions.Update({
            controller: 'role',
            itemId: id,
            data: { name: formProps?.name, actions: formProps?.actions, userType: formProps?.userType },
            onError: message => general.notificationError(message.errorMessageTechnical),
            showLoading: true,
            onSuccess: () => {
              EventBus.sc.onEntityUpdated({ entityType: EntityTypeEnum.Role, entityId: id })
              setIsOpen(false)
              general.messageSuccess('general.update_success')
            },
            callback: () => setLoading(false),
          }),
        )
      }
    }
  }, [formProps])
  return (
    <Modal
      title={general.isNullOrEmpty(id) ? i18next.t("role.form_title.add") : i18next.t("role.form_title.edit")}
      visible={isOpen}
      width={700}
      onCancel={closeModal}
      cancelText={i18next.t("action.close")}
      okText={i18next.t("action.save")}
      onOk={save}
      okButtonProps={{
        disabled: formProps?.userType != UserTypeEnum.Admin
      }}
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} >
          <FormInput
            label={i18next.t('role.form.name')}
            onChange={onChangeName}
            disabled={formProps?.userType != UserTypeEnum.Admin}
            placeholder={i18next.t('role.form.name')}
            value={formProps?.name}
            errorMessage={formProps?.userType == UserTypeEnum.Admin ? validator.register(
              'role',
              formProps?.name,
              [{ rule: 'required' }, { rule: 'maxStringLength', value: 50 }],
              validatorScopeKey,
            ) : null}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} >
          <FormTreeSelect
            label={i18next.t('role.form.role_actions')}
            value={formProps?.actions}
            controller="role"
            disabled={formProps?.userType != UserTypeEnum.Admin}
            treeCheckable={true}
            action="getEmptyActions"
            placeholder={i18next.t('role.form.role_actions')}
            onChange={onChangeRoleAction}
            errorMessage={formProps?.userType == UserTypeEnum.Admin ? validator.register(
              'actions',
              formProps?.actions,
              [{ rule: 'required' }],
              validatorScopeKey,
            ) : null}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default RoleFormModal