import store from "store";
import nProgress from "nprogress";
import { useEffect } from "react";
import general from "utils/general";
import { useDispatch } from "react-redux";
import { Actions as AuthActions } from "redux/auth/reducers"
import { Actions as ApiCallActions } from "redux/apiCall/reducers"
import { Actions as RootActions } from "redux/root/reducers"
import Axios from "axios";
import i18next from "i18next";
import { SortAscendingOutlined } from "@ant-design/icons";
import languageService from "../services/language/language-service";

const loadLanguage = ({ dispatch, onSuccess }) => {

    dispatch(ApiCallActions.List({
        controller: "language",
        action: "dropdown",
        showAlertOnError: true,
        data: {},
        onSuccess: async ({ data }) => {
            const userInfoLanguageId = global?.userInfo?.languageId;
            let defaultLanguage = data.find((item) => item.value == userInfoLanguageId);

            if (userInfoLanguageId == null || userInfoLanguageId == undefined) {
                var pathname = window.location.href.toString();
                var pathnameLength = pathname.split("/").length;
                if (pathnameLength > 6) {
                    var lng = pathname.slice(-2);
                    defaultLanguage = data.find((item) => item.shortName.toLowerCase() == lng.toLowerCase());
                } else {
                    var storeLanguageId = store.get("userLanguageId");
                    if (storeLanguageId != null && storeLanguageId != undefined) {
                        defaultLanguage = data.find((item) => item.value == storeLanguageId)
                    } else {
                        defaultLanguage = data.find((item) => { window.navigator.language.includes(item.shortName.toLowerCase()) });
                    }
                }
            }

            if (!defaultLanguage)
                defaultLanguage = data.find((item) => item.value == store.get("userLanguageId"));

            if (!defaultLanguage)
                defaultLanguage = data.find((item) => item.isDefault);

            store.set("userLanguageId", defaultLanguage?.id == null ? 1 : defaultLanguage.id);
            store.set('app.menu.openedKeys', ["coolant_quality"])

            onSuccess();
        },
        onError: () => {

        }
    }))
}
const loadi18Next = ({ dispatch, onSuccess }) => {
    let languageId = store.get("userLanguageId");
    if (!languageId) {
        languageId = 1;
    }
    dispatch(ApiCallActions.List({
        controller: "dataTranslate",
        action: "dropdown",
        itemId: languageId,
        showAlertOnError: true,
        data: {},
        onSuccess: async ({ data }) => {
            var list = data.map((item) => {
                return (`"${item.keyword.replaceAll("\t","")}" : "${item.value}"`);
            });
            var translateData = JSON.parse('{"' + process.env.I18N_LANG_NAME + '"' + ': {"translation": {' + list.join(",") + '}}} ');
            i18next.init({
                lng: process.env.I18N_LANG_NAME,
                debug: false,
                resources: translateData,
                keySeparator: true
            });
            onSuccess();
        },
        onError: () => {
            setTimeout(() => {
                loadi18Next({ dispatch, onSuccess });
            }, 2000);
        }
    }))
}

const loadUserInfo = ({ dispatch, onSuccess }) => {
    const token = store.get("token") || "";
    const refreshToken = store.get("refreshToken") || "";
    if (!general.isNullOrEmpty(token)) { //user not logged in
        dispatch(AuthActions.setAuthState({ // set token
            token: token,
            isUserAuthorized: true
        }));
        global.token = token;
        var countError = 0;
        dispatch(ApiCallActions.NativeGet({
            controller: "auth",
            action: "userinfo",
            showAlertOnError: true,
            onSuccess: ({ data }) => {
                if (data == null) {
                    localStorage.clear();
                }
                dispatch(AuthActions.setAuthState({ // update user info
                    userInfo: data
                }));
                global.userInfo = data;
                global.token = token;
                global.roles = new Set(data?.role?.actions);
                store.set("userLanguageId", data.languageId);
                onSuccess();
            },
            onError: () => {
                const languageId = store.get("userLanguageId");
                store.remove("token");
                localStorage.clear();
                store.set("userLanguageId", languageId);
                general.notificationSuccess("general.logout_success");
                dispatch(AuthActions.setAuthState({
                    isUserAuthorized: false,
                    token: "",
                }));
                window.location.reload();
            }
        }))
        dispatch(ApiCallActions.NativePost({
            controller: "auth",
            action: "refreshToken",
            data: { refreshToken: refreshToken },
            showAlertOnError: true,
            onSuccess: ({ data }) => {
                store.set("refreshToken", data?.refreshToken);
                global.refreshToken = refreshToken;
                dispatch(AuthActions.setAuthState({
                    userInfo: data?.user,
                    token: data?.accessToken,
                    refreshToken: data?.refreshToken,
                    isUserAuthorized: true
                }));
            },
        }))
    } else {
        onSuccess();
    }

}


const LoadApp = ({ }) => {
    const dispatch = useDispatch();
    useEffect(() => {

        loadUserInfo({
            dispatch,
            onSuccess: () => {
                dispatch(RootActions.setRootState({
                    appIsLoading: false
                }));

                loadLanguage({
                    dispatch,
                    onSuccess: () => {
                        dispatch(RootActions.setRootState({
                            languageLoading: false
                        }));

                        loadi18Next({
                            dispatch,
                            onSuccess: () => {
                                dispatch(RootActions.setRootState({
                                    i18nextLoading: false
                                }));
                            }
                        });
                    }
                })
            }
        });
    }, []);
    return null;
}



export default LoadApp;