import React, { useCallback, useEffect, useState } from "react"
import { Actions as ApiCallActions } from "redux/apiCall/reducers"
import { useDispatch } from "react-redux";
import Validator from "components/high-level/validator";
import { FormInputPassword, FormInput, FormAction, FormInputMask, FormContainer, FormSelect, FormSwitch, FormTreeSelect } from "components/high-level/form";
import { CloseOutlined, MailOutlined, SaveOutlined, UserOutlined, KeyOutlined, PlusCircleOutlined, PhoneOutlined } from '@ant-design/icons';
import general from "utils/general";
import i18next from "i18next";
import { Modal, Row, Col } from 'antd';
import { StatusEnum } from 'utils/enums';
import EventBus from "infrastructure/event-bus/event-bus";
import { EntityTypeEnum } from "utils/enums/entity-type-enum";
import FormMultiSelect from "components/high-level/form/form-multi-select";

const LanguageFormModal = ({ nodeKey, id }) => {
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(true);
    const closeModal = () => {
        setIsOpen(false);
    }

    const [, reRender] = useState(null);
    const [validator,] = useState(new Validator());
    const validatorScopeKey = validator.generateNewScopeKey();

    const [loading, setLoading] = useState(false);
    const [formProps, setFormProps] = useState({});
    const [defaultProps, setDefaultProps] = useState({});
   
    const updateFormProps = (values) => {
        setFormProps(curr => ({ ...curr, ...values, }));
        
    };
    const [loadAndSelectDeviceGroup, setLoadAndSelectDeviceGroup] = useState([]);
    const [loadAndSelectOrganizationGroup, setLoadAndSelectOrganizationGroup] = useState([]);
    const [parentOrganization, setParentOrganization] = useState(null);

    const generatePassword = useCallback(() => {
        updateFormProps({
            password: general.generateRandomString(10)
        })
    })

    useEffect(() => {
        if (!general.isNullOrEmpty(id)) {
            setLoading(true);
            dispatch(ApiCallActions.Detail({
                controller: "language",
                itemId: id,
                showLoading: true,
                showAlertOnError: true,
                onSuccess: async ({ data }) => {
                    var newData = {
                        ...data
                    }
                    setLoading(false);
                    setFormProps(newData);
                    setDefaultProps(newData);
                }
            }))
        }
    }, []);

    const save = useCallback(() => {
        const isValid = validator.allValid();
        reRender(1);
        if (isValid) {
            setLoading(true);
            if (general.isNullOrEmpty(id)) { // create
                dispatch(ApiCallActions.Save({
                    controller: "language",
                    data: { ...formProps },
                    showLoading: true,
                    onSuccess: ({ data }) => {
                        EventBus.sc.onEntityCreated({ entityType: EntityTypeEnum.Language, entityId: data.id });
                        setIsOpen(false);
                        general.messageSuccess("general.save_success");
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                       
                    },
                    onError: (message) => general.notificationError(message.errorMessageTechnical),
                    callback: () => setLoading(false)
                }))

            } else { // update
                dispatch(ApiCallActions.Update({
                    controller: "language",
                    itemId: id,
                    data: { ...formProps },
                    onSuccess: () => {
                        EventBus.sc.onEntityUpdated({ entityType: EntityTypeEnum.Language, entityId: id });
                        setIsOpen(false);
                        general.messageSuccess("general.update_success");
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    },
                    onError: (message) => general.notificationError(i18next.t(message.errorMessageTechnical)),
                    callback: () => setLoading(false)
                }))
            }
        }
    }, [formProps])

    useEffect(() => {
        updateFormProps({
            isRtl: false,
            isDefault: false
        })
    }, [])

    const onChangeName = useCallback((val) => updateFormProps({ name: val }), []);
    const onChangeShortName = useCallback((val) => updateFormProps({ shortName: val }), []);
    const onChangeIsRtl = useCallback((val) => updateFormProps({ isRtl: val }), []);
    const onChangeIsDefault = useCallback((val) => updateFormProps({ isDefault: val }), []);
    const onChangeStatus = useCallback((val) => updateFormProps({ status: val ? StatusEnum.Active : StatusEnum.Passive }), []);

    return (
        <Modal
            title={general.isNullOrEmpty(id) ? i18next.t("language.form_title.add") : i18next.t("language.form_title.edit")}
            visible={isOpen}
            width={700}
            onCancel={closeModal}
            cancelText={i18next.t("action.close")}
            okText={i18next.t("action.save")}
            onOk={save}
        >
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <FormInput
                        label={i18next.t("language.form.name")}
                        placeholder={i18next.t("language.form.name")}
                        onChange={onChangeName}
                        value={formProps?.name}

                        errorMessage={validator.register("name", formProps?.name, [{ rule: "required" }, { rule: "maxStringLength", value: 30 }], validatorScopeKey)}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <FormInput
                        label={i18next.t("language.form.short_name")}
                        placeholder={i18next.t("language.form.short_name")}
                        onChange={onChangeShortName}
                        value={formProps?.shortName}

                        errorMessage={validator.register("shortName", formProps?.shortName, [{ rule: "required" }, { rule: "maxStringLength", value: 5 }], validatorScopeKey)}
                    />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <FormSwitch
                        label={i18next.t("language.form.is_rtl")}
                        checkedChildren={i18next.t("general.yes")}
                        unCheckedChildren={i18next.t("general.no")}
                        checked={formProps?.isRtl}
                        onChange={onChangeIsRtl}
                    />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <FormSwitch
                        label={i18next.t("language.form.is_default")}
                        checkedChildren={i18next.t("general.yes")}
                        unCheckedChildren={i18next.t("general.no")}
                        checked={formProps?.isDefault}
                        onChange={onChangeIsDefault}
                        disabled={defaultProps?.isDefault ? true : false || defaultProps?.status == StatusEnum.Passive ? true : false}
                    />
                </Col>
                {!general.isNullOrEmpty(id) &&
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <FormSwitch
                            label={i18next.t("form.status")}
                            checkedChildren={i18next.t("form.status.active")}
                            unCheckedChildren={i18next.t("form.status.passive")}
                            checked={formProps?.status == StatusEnum.Active}
                            onChange={onChangeStatus}
                            disabled={formProps?.isDefault}
                        />
                    </Col>
                }
            </Row>
        </Modal>
    )
}

export default LanguageFormModal;