import { CloseOutlined, KeyOutlined, MailOutlined, PhoneOutlined, PlusCircleOutlined, SaveOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import { FormAction, FormContainer, FormInput, FormInputMask, FormInputNumber, FormInputPassword, FormMultiFileUpload, FormSelect, FormSwitch, FormTreeSelect } from "components/high-level/form";
import FormMultiSelect from "components/high-level/form/form-multi-select";
import Validator from "components/high-level/validator";
import memoize from "fast-memoize";
import i18next from "i18next";
import EventBus from "infrastructure/event-bus/event-bus";
import $ from "jquery";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Actions as ApiCallActions } from "redux/apiCall/reducers";
import { FileTypeEnum, OrganizationTypeEnum, StatusEnum, UserTypeEnum } from 'utils/enums';
import { EntityTypeEnum } from "utils/enums/entity-type-enum";
import general from "utils/general";
import useRefState from "utils/use-ref-state";
const ServiceTypeFormModal = ({ nodeKey, id }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(true);
  const closeModal = () => {
    setIsOpen(false);
  }

  const [, reRender] = useState(null);
  const [validator,] = useState(new Validator());
  const validatorScopeKey = validator.generateNewScopeKey();
  const [fileLoading, setFileLoading] = useState(false)

  const [loading, setLoading] = useState(false);
  const [formProps, formPropsRef, setFormProps] = useRefState({});
  const updateFormProps = (values) => {
    setFormProps(curr => ({ ...curr, ...values, }))
  };
  const [loadAndSelectDeviceGroup, setLoadAndSelectDeviceGroup] = useState([]);
  const [loadAndSelectOrganizationGroup, setLoadAndSelectOrganizationGroup] = useState([]);
  const priceChange = (number) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'SFR' }).format(number).replace(",00", "").replace("SFR", "").replace(" ", "").replace("\u00A0", "");
  useEffect(() => {
    if (!general.isNullOrEmpty(id)) {
      setLoading(true);
      dispatch(ApiCallActions.Detail({
        controller: "serviceType",
        itemId: id,
        showLoading: true,
        showAlertOnError: true,
        onSuccess: async ({ data }) => {
          setLoading(false);
          setFormProps(data);
        }
      }))
    }
  }, []);

  const save = () => {
    const data = { ...formProps }
    data.price = general.defaultPrice(data.price)
    data.commission = general.defaultPrice(data.commission)
    const isValid = validator.allValid();
    if (fileLoading) return general.messageWarning("general.loading_file");
    reRender(1);
    if (isValid) {
      if (data.commission >= data.price) {
        general.messageWarning("general.comission_and_price_valid");
      }
      else {
        setLoading(true);
        if (general.isNullOrEmpty(id)) {
          dispatch(ApiCallActions.Save({
            controller: "serviceType",
            data,
            showLoading: true,
            showAlertOnError: true,
            onSuccess: ({ data }) => {
              EventBus.sc.onEntityCreated({ entityType: EntityTypeEnum.ServiceType, entityId: data.id });
              setIsOpen(false);
              general.messageSuccess("general.save_success");
            },
            // onError: (message) => {
            //   general.notificationError(i18next.t(message.errorMessage));
            // },
            callback: () => setLoading(false)
          }))

        } else {
          const newData = { ...data }
          const oldparentservicetypename = newData.parentservicetypename
          newData.parentServiceTypeId = newData.parentServiceType
          delete newData.parentservicetypename
          dispatch(ApiCallActions.Update({
            controller: "serviceType",
            itemId: id,
            data: { ...newData },
            onSuccess: () => {
              EventBus.sc.onEntityUpdated({ entityType: EntityTypeEnum.ServiceType, entityId: id });
              setIsOpen(false);
              general.messageSuccess("general.update_success");
            },
            onError: (message) => {
              newData.parentservicetypename = oldparentservicetypename
              general.notificationError(i18next.t(message.errorMessage));
            },
            callback: () => setLoading(false)
          }))
        }
      }
    }
  }
  const onChangeName = useCallback((val) => updateFormProps({ name: val }), []);
  const onChangePrice = useCallback((val) => {
    updateFormProps({ price: val })
  }, []);
  const onChangeCommission = useCallback((val) => {
    updateFormProps({ commission: val })
  }, []);
  const onChangeDescription = useCallback((val) => updateFormProps({ description: val }), []);
  const onChangeStatus = useCallback((val) => updateFormProps({ status: val ? StatusEnum.Active : StatusEnum.Passive }), []);
  const onChangeFileLoading = val => {
    setFileLoading(val)
  }

  const onChangeServiceType = useCallback(
    parentServiceType =>
      updateFormProps({ parentServiceType: parentServiceType?.value, parentservicetypename: parentServiceType?.name }),
    [],
  )

  const onChangeImageUrl = useCallback(val => updateFormProps({ imageUrl: val }), [])

  return (
    <Modal
      title={general.isNullOrEmpty(id) ? i18next.t("service_type.form_title.add") : i18next.t("service_type.form_title.edit")}
      visible={isOpen}
      width={700}
      onCancel={closeModal}
      cancelText={i18next.t("action.close")}
      okText={i18next.t("action.save")}
      onOk={save}
      okButtonProps={{ disabled: fileLoading }}
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} >
          <FormSelect
            controller="serviceType"
            // disabled={id != null}
            label={i18next.t("service_type.form.parent_service_type")}
            selectedId={Number(formProps?.parentServiceType)}
            selectedName={i18next.t(formProps?.parentservicetypename)}
            onChange={onChangeServiceType}
            filter={{ parentServiceTypeId: null }}
            disableSelfId={id}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} >
          <FormInput
            label={i18next.t("service_type.form.name")}
            placeholder={i18next.t("service_type.form.name")}
            onChange={onChangeName}
            value={formProps?.name}
            errorMessage={validator.register("name", formProps?.name, [
              { rule: "required" },
              { rule: 'maxStringLength', value: 50 },], validatorScopeKey)}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} >
          <FormInput
            label={i18next.t("service_type.form.price") + " (" + process.env.CURRENCYICON + ")"}
            placeholder={i18next.t("service_type.form.price")}
            onChange={onChangePrice}
            value={formProps?.price}
            errorMessage={validator.register("price", general.defaultPrice(formProps.price), [
              { rule: "required" },
              {rule: 'isFloat'}, 
              { rule: 'maxNumber', value: 1000000 }
            ], validatorScopeKey)}
            usePriceFormat
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} >
          <FormInput
            usePriceFormat
            label={i18next.t("service_type.form.commission") + " (" + process.env.CURRENCYICON + ")"}
            placeholder={i18next.t("service_type.form.commission")}
            onChange={onChangeCommission}
            value={formProps?.commission}
            errorMessage={validator.register("commission", general.defaultPrice(formProps.commission),
            [
              {rule: 'isFloat'},
              { rule: "required" }, 
              { rule: 'maxCommision', value: general.defaultPrice(formProps.price)}
            ], validatorScopeKey)}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} >
          <FormInput
            label={i18next.t("service_type.form.description")}
            placeholder={i18next.t("service_type.form.description")}
            onChange={onChangeDescription}
            value={formProps?.description}
            errorMessage={validator.register("description", formProps?.description, [
              { rule: 'maxStringLength', value: 100 }], validatorScopeKey)}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} >
          <FormMultiFileUpload
            accept={"image/*"}
            label={i18next.t("service_type.form.image")}
            maxFileCount={1}
            fileType={FileTypeEnum.ServiceType}
            value={formProps?.imageUrl}
            onChangeLoading={onChangeFileLoading}
            onChange={onChangeImageUrl}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default ServiceTypeFormModal;
