import { LoadingOutlined } from '@ant-design/icons'
import { Col, Empty, Modal, Row, Table } from 'antd'
import { FormTreeSelect } from 'components/high-level/form'
import FormMultiSelect from 'components/high-level/form/form-multi-select'
import i18next from 'i18next'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Actions as ApiCallActions } from 'redux/apiCall/reducers'
import general from 'utils/general'
import Validator from 'components/high-level/validator'

const { Column } = Table
const UserInterestServiceTypesModal = ({ nodeKey, id }) => {
  const dispatch = useDispatch()
  const [validator] = useState(new Validator())
  const validatorScopeKey = validator.generateNewScopeKey
  const [loading, setLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(true)
  const closeModal = () => {
    setIsOpen(false)
  }
  const [, reRender] = useState(null)
  const [formProps, setFormProps] = useState([])

  useEffect(() => {
    if (!general.isNullOrEmpty(id)) {
      setLoading(true)
      dispatch(
        ApiCallActions.NativeGet({
          controller: 'user',
          action: 'serviceTypes/' + id,
          showLoading: true,
          showAlertOnError: true,
          onSuccess: async ({ data }) => {
            console.log(data)
            setFormProps({ actions: data })
            setLoading(false)
          },
          enError: err => {
            console.log(err)
            setLoading(false)
          },
        }),
      )
    }
  }, [])

  const onChangeServiceType = val => {
    // console.log('onChangeServiceType', val)
    // const onChangeRoleAction = useCallback(val => updateFormProps({ actions: val }), [])
    setFormProps({ actions: val })
  }

  const save = () => {
    const isValid = validator.allValid()
    reRender(1)

    if (isValid) {
      console.log('formProps', { serviceTypes: formProps?.actions })
      dispatch(
        ApiCallActions.Update({
          controller: 'user',
          action: 'serviceTypes',
          itemId: id,
          data: { actions: formProps?.actions },
          onError: message => general.notificationError(message.errorMessageTechnical),
          showLoading: true,
          onSuccess: () => {
            setIsOpen(false)
            general.messageSuccess('general.update_success')
          },
          callback: () => setLoading(false),
        }),
      )
    }
  }

  return (
    <Modal
      title={i18next.t('user.form_title.user_interest_service_types')}
      visible={isOpen}
      width={1000}
      onCancel={closeModal}
      // okButtonProps={{ disabled: true, hidden: true }}
      cancelText={i18next.t('action.close')}
      okText={i18next.t('action.save')}
      onOk={save}
    >
      {!loading && (
        <>
          {/* <Table dataSource={formProps} pagination={false} showHeader={false}>
            <Column
              onHeaderCell={column => {}}
              // title={i18next.t('table.title.service_type_name')}
              dataIndex="name"
              key="name"
            />
          </Table> */}
          <FormTreeSelect
            label={i18next.t('user_service_type.form.service_actions')}
            value={formProps?.actions}
            controller="serviceType"
            treeCheckable={true}
            action="webThreeList"
            placeholder={i18next.t('user_service_type.form.service_actions')}
            onChange={onChangeServiceType}
            errorMessage={validator.register(
              'actions',
              formProps?.actions,
              [{ rule: 'required' }],
              validatorScopeKey,
            )}
          />
        </>
      )}
    </Modal>
  )
}

export default UserInterestServiceTypesModal
