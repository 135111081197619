import React, { useCallback, useEffect, useState } from 'react'
import { Actions as ApiCallActions } from 'redux/apiCall/reducers'
import { useDispatch } from 'react-redux'
import ModalFullOverlayContainer from 'components/high-level/modal/modal-full-overlay'
import Validator from 'components/high-level/validator'
import { FormContainer, FormInput, FormAction, FormSelect, FormTreeSelect, FormInputPassword } from 'components/high-level/form'
import { CloseOutlined, EyeOutlined, KeyOutlined, SaveOutlined } from '@ant-design/icons'
import general from 'utils/general'
import i18next from 'i18next'
import useRefState from 'utils/use-ref-state'
import { TreeSelect, Form, Modal } from 'antd'
import 'antd/dist/antd.css'
import { UserTypeEnum, OrganizationTypeEnum } from '../../utils/enums'
import { EntityTypeEnum } from 'utils/enums/entity-type-enum'
import EventBus from 'infrastructure/event-bus/event-bus'
import { Actions as ModalActions } from 'redux/modal/reducers'
import ModalTypes from 'utils/modal-types'
import store from "store";
import { Actions as AuthActions } from "redux/auth/reducers";

const ChangePasswordModal = ({ nodeKey, id }) => {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(true)
    const closeModal = () => {
        setIsOpen(false)
    }
    const [, reRender] = useState(null)
    const [loading, setLoading] = useState(false)
    const [validator] = useState(new Validator())
    const validatorScopeKey = validator.generateNewScopeKey()
    const [formProps, formPropsRef, setFormProps] = useRefState({})
    const updateFormProps = values => setFormProps(curr => ({ ...curr, ...values }), [])

    const onChangeCurrentPassword = useCallback((e) => updateFormProps({ currentPassword: e }), []);
    const onChangeNewPassword = useCallback((e) => updateFormProps({ newPassword: e }), []);
    const onChangeConfirmPassword = useCallback((e) => updateFormProps({ confirmPassword: e }), []);

    const changePassword = useCallback(() => {
        const isValid = validator.allValid();
        reRender(1);
        if (isValid) {
            if (formPropsRef?.current?.newPassword != formPropsRef?.current?.confirmPassword) {
                general.messageWarning(i18next.t('message.current_password_must_not_be_same_as_new_password'))
                return;
            }
            dispatch(ApiCallActions.Update({
                controller: "user",
                action: "update/changePassword",
                data: {
                    id: id,
                    password: formProps.newPassword,
                },
                showLoading: true,
                showAlertOnError: true,
                onSuccess: async ({ data }) => {
                    if (data) {
                        general.messageSuccess(i18next.t('message.password_successfully_changed'))
                        closeModal();
                    }
                    else
                        general.messageError(i18next.t('message.password_not_changed'))
                }
            }))
        }
    }, [formProps])

    return (
        <Modal
            title={i18next.t("change_password_modal.title")}
            visible={isOpen}
            width={800}
            onCancel={closeModal}
            cancelText={i18next.t("action.close")}
            okText={i18next.t("action.save")}
            onOk={changePassword}
        >
            <Form>
                <div className="row">
                    <div className="col-12">
                        <div style={{ position: 'relative' }}>
                            <FormInputPassword
                                type="password"
                                label={i18next.t("change_password_modal.new_password")}
                                placeholder={i18next.t("change_password_modal.new_password")}
                                onChange={onChangeNewPassword}
                                value={formProps?.newPassword}
                                errorMessage={validator.register("newPassword", formProps?.newPassword, [{ rule: "required" }, { rule: "minStringLength", value: 6 }, { rule: "maxStringLength", value: 20 }], validatorScopeKey)}
                                prefix={<KeyOutlined />}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div style={{ position: 'relative' }}>
                            <FormInputPassword
                                type="password"
                                label={i18next.t("change_password_modal.confirm_password")}
                                placeholder={i18next.t("change_password_modal.confirm_password")}
                                onChange={onChangeConfirmPassword}
                                value={formProps?.confirmPassword}
                                errorMessage={validator.register("confirmPassword", formProps?.confirmPassword, [{ rule: "required" }, { rule: "minStringLength", value: 6 }, { rule: "maxStringLength", value: 20 }], validatorScopeKey)}
                                prefix={<KeyOutlined />}
                            />
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    )
}

export default React.memo(ChangePasswordModal)