import {
  CloseOutlined, KeyOutlined, MailOutlined, PhoneOutlined, PlusCircleOutlined, SaveOutlined,
  UserOutlined
} from '@ant-design/icons'
import { Col, Modal, Row } from 'antd'
import {
  FormAction, FormContainer, FormInput, FormInputMask, FormInputPassword, FormSelect,
  FormSwitch,
  FormTreeSelect
} from 'components/high-level/form'
import FormDatepicker from 'components/high-level/form/form-date-picker'
import FormMultiSelect from 'components/high-level/form/form-multi-select'
import Validator from 'components/high-level/validator'
import i18next from 'i18next'
import EventBus from 'infrastructure/event-bus/event-bus'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Actions as ApiCallActions } from 'redux/apiCall/reducers'
import { OrganizationTypeEnum, StatusEnum, UserTypeEnum } from 'utils/enums'
import { EntityTypeEnum } from 'utils/enums/entity-type-enum'
import general from 'utils/general'
import useRefState from 'utils/use-ref-state'

const UserFormModal = ({ nodeKey, id }) => {
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(true)
  const closeModal = () => {
    setIsOpen(false)
  }

  const [, reRender] = useState(null)
  const [validator] = useState(new Validator())
  const validatorScopeKey = validator.generateNewScopeKey()

  const [loading, setLoading] = useState(false)
  const [formProps, formPropsRef, setFormProps] = useRefState({ userType: UserTypeEnum.Admin })
  const updateFormProps = values => {
    setFormProps(curr => ({ ...curr, ...values }))
  }
  const [loadAndSelectDeviceGroup, setLoadAndSelectDeviceGroup] = useState([])
  const [loadAndSelectOrganizationGroup, setLoadAndSelectOrganizationGroup] = useState([])
  const [parentOrganization, setParentOrganization] = useState(null)

  const generatePassword = useCallback(() => {
    updateFormProps({
      password: general.generateRandomString(10),
    })
  })

  useEffect(() => {
    if (!general.isNullOrEmpty(id)) {
      setLoading(true)
      dispatch(
        ApiCallActions.Detail({
          controller: 'user',
          itemId: id,
          showLoading: true,
          showAlertOnError: true,
          onSuccess: async ({ data }) => {
            var newData = {
              ...data,
              // userType: UserTypeEnum.Admin,
              roleId: data?.role?.id,
              roleName: data?.role?.name,
            }
            setLoading(false)
            setFormProps(newData)
          },
        }),
      )
    }
  }, [])

  const save = useCallback(() => {
    const isValid = validator.allValid()
    reRender(1)
    if (isValid) {
      setLoading(true)
      if (general.isNullOrEmpty(id)) {
        // create
        dispatch(
          ApiCallActions.Save({
            controller: 'user',
            data: {
              ...formProps
            },
            showLoading: true,
            onSuccess: ({ data }) => {
              EventBus.sc.onEntityCreated({ entityType: EntityTypeEnum.User, entityId: data.id })
              setIsOpen(false)
              general.messageSuccess('general.save_success')
            },
            onError: message => general.notificationError(message.errorMessageTechnical),
            callback: () => setLoading(false),
          }),
        )
      } else {
        // update
        dispatch(
          ApiCallActions.Update({
            controller: 'user',
            itemId: id,
            data: {
              name: formPropsRef.current?.name,
              surname: formPropsRef.current?.surname,
              username: formPropsRef.current?.username,
              email: formPropsRef.current?.email,
              phone: formPropsRef.current?.phone,
              appMobileNotifActive: formPropsRef.current?.appMobileNotifActive,
              appMailActive: formPropsRef.current?.appMailActive,
              workMobileNotifActive: formPropsRef.current?.workMobileNotifActive,
              workMailActive: formPropsRef.current?.workMailActive,
              birthDate: formPropsRef.current?.birthDate,
              languageId: formPropsRef.current?.languageId,
              roleId: formPropsRef.current?.roleId,
              status: formPropsRef.current?.status,
            },
            onSuccess: ({ data }) => {
              EventBus.sc.onEntityUpdated({ entityType: EntityTypeEnum.User, entityId: id })

              if (data && data.updateIds)
                data.updateIds.map((updateId) => {
                  EventBus.sc.onEntityUpdated({ entityType: EntityTypeEnum.User, entityId: updateId })
                })
              setIsOpen(false)
              general.messageSuccess('general.update_success')
            },
            onError: message => general.notificationError(i18next.t(message.errorMessageTechnical)),
            callback: () => setLoading(false),
          }),
        )
      }
    }
  }, [formProps])

  const onChangeUsername = useCallback(
    val => updateFormProps({ username: val?.replaceAll(' ', '')?.trim() }),
    [],
  )
  const onChangeName = useCallback(val => updateFormProps({ name: val }), [])
  const onChangeSurname = useCallback(val => updateFormProps({ surname: val }), [])
  const onChangeAppMailActive = useCallback(val => updateFormProps({ appMailActive: val }), [])
  const onChangeAppMobileNotifActive = useCallback(
    val => updateFormProps({ appMobileNotifActive: val }),
    [],
  )
  const onChangeWorkMailActive = useCallback(val => updateFormProps({ workMailActive: val }), [])
  const onChangeWorkMobileNotifActive = useCallback(
    val => updateFormProps({ workMobileNotifActive: val }),
    [],
  )
  const onChangeEmail = useCallback(val => updateFormProps({ email: val }), [])
  const onChangePassword = useCallback(val => updateFormProps({ password: val }), [])
  const onChangeIdentityNo = useCallback(val => updateFormProps({ identityNo: val }), [])
  const onChangeRole = useCallback(e => updateFormProps({ roleId: e?.id, roleName: e?.name }), [])
  const onChangePhone = useCallback(val => updateFormProps({ phone: val }), [])
  const onChangeBirthDate = useCallback(val => updateFormProps({ birthDate: val }), [])

  const onSelect = useCallback(organization => {
    updateFormProps({
      roleId: null,
      roleName: null,
    })
    setParentOrganization(organization?.parentOrganizationName)
  }, [])

  const onChangeStatus = useCallback(
    val => updateFormProps({ status: val ? StatusEnum.Active : StatusEnum.Passive }),
    [],
  )
  const day = new Date();
  return (
    <Modal
      title={
        general.isNullOrEmpty(id)
          ? i18next.t('user.form_title.add')
          : i18next.t('user.form_title.edit')
      }
      visible={isOpen}
      width={1000}
      onCancel={closeModal}
      cancelText={i18next.t('action.close')}
      okText={i18next.t('action.save')}
      onOk={save}
    >
      {(!id || formProps.id) &&
        <Row>
          {formProps.userType == UserTypeEnum.Admin &&
            <Col xs={24} sm={24} md={12} lg={12} className="pl-3">
              <FormSelect
                controller="role"
                label={i18next.t('form.role')}
                selectedId={formProps?.roleId}
                selectedName={formProps?.roleName}
                onChange={onChangeRole}
                filter={{
                  userType: formProps?.userType,
                }}
                hideDeleted={true}
                errorMessage={
                  formProps?.userType == UserTypeEnum.Admin
                    ? validator.register(
                      'roleId',
                      formProps?.roleId,
                      [{ rule: 'required' }],
                      validatorScopeKey,
                    )
                    : null
                }
              />
            </Col>
          }
          <Col xs={12} sm={12} md={12} lg={12} className="pl-3">
            <FormInput
              label={i18next.t('user.form.name')}
              placeholder={i18next.t('user.form.name')}
              onChange={onChangeName}
              value={formProps?.name}
              errorMessage={validator.register(
                'name',
                formProps?.name,
                [
                  { rule: 'required' },
                  { rule: 'minStringLength', value: 3 },
                  { rule: 'maxStringLength', value: 50 },
                ],
                validatorScopeKey,
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="pl-3">
            <FormInput
              label={i18next.t('user.form.surname')}
              placeholder={i18next.t('user.form.surname')}
              onChange={onChangeSurname}
              value={formProps?.surname}
              errorMessage={validator.register(
                'surname',
                formProps?.surname,
                [
                  { rule: 'minStringLength', value: 3 },
                  { rule: 'required' },
                  { rule: 'maxStringLength', value: 50 },
                ],
                validatorScopeKey,
              )}
            />
          </Col>
          {formProps.userType == UserTypeEnum.Admin &&
            <Col xs={12} sm={12} md={12} lg={12} className="pl-3">
              <FormInput
                label={i18next.t('user.form.username')}
                placeholder={i18next.t('user.form.username')}
                onChange={onChangeUsername}
                value={formProps?.username}
                errorMessage={validator.register(
                  'username',
                  formProps?.username,
                  [
                    { rule: 'minStringLength', value: 3 },
                    { rule: 'required' },
                    { rule: 'maxStringLength', value: 50 },
                  ],
                  validatorScopeKey,
                )}
              />
            </Col>
          }

          <Col xs={24} sm={24} md={12} lg={12} className="pl-3">
            <FormInput
              label={i18next.t('user.form.email')}
              placeholder={i18next.t('user.form.email')}
              onChange={onChangeEmail}
              value={formProps?.email}
              disabled={formProps.loggedInWithSocial}
              errorMessage={validator.register(
                'email',
                formProps?.email,
                [{ rule: 'isEmail' }, { rule: 'required' }, { rule: 'maxStringLength', value: 50 }],
                validatorScopeKey,
              )}
            />
          </Col>
          {general.isNullOrEmpty(id) && (
            <Col xs={12} sm={12} md={12} lg={12} className="pl-3">
              <div style={{ position: 'relative' }}>
                <FormInputPassword
                  type="password"
                  label={i18next.t('user.form.password')}
                  placeholder={i18next.t('user.form.password')}
                  onChange={onChangePassword}
                  value={formProps?.password}
                  errorMessage={validator.register(
                    'password',
                    formProps?.password,
                    general.isNullOrEmpty(id)
                      ? [
                        { rule: 'required' },
                        { rule: 'minStringLength', value: 6 },
                        { rule: 'maxStringLength', value: 20 },
                      ]
                      : formProps.password != null
                        ? [
                          { rule: 'minStringLength', value: 6 },
                          { rule: 'maxStringLength', value: 20 },
                        ]
                        : [],
                    validatorScopeKey,
                  )}
                />
                <span
                  onClick={generatePassword}
                  style={{
                    position: 'absolute',
                    top: 3,
                    right: 0,
                    fontSize: 11,
                    color: '#4b7cf3',
                    cursor: 'pointer',
                  }}
                >
                  ({i18next.t('form.generate_password')})
                </span>
              </div>
            </Col>
          )}
          {formProps.userType != UserTypeEnum.Admin &&
            <>
              <Col xs={24} sm={24} md={12} lg={12} className="pl-3">
                <FormInput
                  label={i18next.t('user.form.phone')}
                  placeholder={i18next.t('user.form.phone')}
                  onChange={onChangePhone}
                  value={formProps?.phone}
                  errorMessage={validator.register(
                    'phone',
                    formProps?.phone,
                    [{ rule: 'isNumber' }],
                    validatorScopeKey,
                  )}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} className="pl-3">
                <FormDatepicker
                  label={i18next.t('user.form.birth_date')}
                  placeholder={i18next.t('user.form.birth_date')}
                  defaultValue={day}
                  value={formProps?.birthDate}
                  checked={formProps?.birthDate}
                  onChange={onChangeBirthDate}
                  maxDate={`${(day.getFullYear() - 18)}.${day.getMonth() + 1}.${day.getDate()}`}
                />
              </Col>
            </>
          }
          <Col xs={12} sm={12} md={12} lg={12} className="pl-3">
            <FormSwitch
              label={i18next.t('user.form.want_get_app_email')}
              checkedChildren={i18next.t('general.yes')}
              unCheckedChildren={i18next.t('general.no')}
              checked={formProps?.appMailActive}
              onChange={onChangeAppMailActive}
            />
          </Col>
          {formProps.userType != UserTypeEnum.Admin &&
            <>
              <Col xs={12} sm={12} md={12} lg={12} className="pl-3">
                <FormSwitch
                  label={i18next.t('user.form.want_get_app_notification')}
                  checkedChildren={i18next.t('general.yes')}
                  unCheckedChildren={i18next.t('general.no')}
                  checked={formProps?.appMobileNotifActive}
                  onChange={onChangeAppMobileNotifActive}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} className="pl-3">
                <FormSwitch
                  label={i18next.t('user.form.want_get_work_email')}
                  checkedChildren={i18next.t('general.yes')}
                  unCheckedChildren={i18next.t('general.no')}
                  checked={formProps?.workMailActive}
                  onChange={onChangeWorkMailActive}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} className="pl-3">
                <FormSwitch
                  label={i18next.t('user.form.want_get_work_notification')}
                  checkedChildren={i18next.t('general.yes')}
                  unCheckedChildren={i18next.t('general.no')}
                  checked={formProps?.workMobileNotifActive}
                  onChange={onChangeWorkMobileNotifActive}
                />
              </Col>
            </>
          }
        </Row>
      }
    </Modal>
  )
}

export default UserFormModal
