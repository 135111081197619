const RoleActionEnum = {
    Role: 100,
    RoleList: 101,
    RoleCreate: 102,
    RoleUpdate: 103,
    RoleDelete: 104,

    User: 150,
    UserList: 151,
    UserCreate: 152,
    UserUpdate: 153,
    UserDelete: 154,

    Language: 200,
    LanguageList: 201,
    LanguageCreate: 202,
    LanguageUpdate: 203,
    LanguageDelete: 204,

    Translate: 250,
    TranslateList: 251,
    TranslateCreate: 252,
    TranslateUpdate: 253,
    TranslateDelete: 254,

    MissingTranslate: 300,
    MissingTranslateList: 301,
    MissingTranslateCreate: 302,
    MissingTranslateUpdate: 303,
    MissingTranslateDelete: 304,

    // Location: 350,
    // LocationList: 351,
    // LocationCreate: 352,
    // LocationUpdate: 353,
    // LocationDelete: 354,

    ServiceType: 400,
    ServiceTypeList: 401,
    ServiceTypeCreate: 402,
    ServiceTypeUpdate: 403,
    ServiceTypeDelete: 404,

    Work: 450,
    WorkList: 451,
    WorkCreate: 452,
    WorkUpdate: 453,
    WorkDelete: 454,
    WorkMovementList: 455,
    WorkMovementDelete: 456,
    WorkDocumentList: 457,
    WorkDocumentDelete: 458,
    WorkOfferList: 459,
    WorkOfferDelete: 460,

    Geozone: 500,
    GeozoneList: 501,
    GeozoneCreate: 502,
    GeozoneDelete: 503,
    GeozoneUpdate: 504,

    InsuranceCompanies: 550,
    InsuranceCompaniesList: 551,
    InsuranceCompaniesCreate: 552,
    InsuranceCompaniesDelete: 553,
    InsuranceCompaniesUpdate: 554,

    UserDocuments: 600,
    UserDocumentsList: 601,
    UserDocumentsCreate: 602,
    UserDocumentsDelete: 603,
    UserDocumentsUpdate: 604
}

export default RoleActionEnum